body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html {
  --foreground: #000000;
  --background: #ffffff;

  --page-content-bg: #fafafa;

  --accent-0: #fafafa;
  --accent-05: #f6f6f6;
  --accent-1: #efefef;
  --accent-2: #dfdfdf;
  --accent-3: #cccccc;
  --accent-4: #bbbbbb;
  --accent-5: #888888;
  --accent-6: #666666;
  --accent-7: #444444;
  --accent-8: #202020;

  --shadow-smallest: 0px 2px 6px rgba(100, 100, 100, 0.08);
  --shadow-small: 0 5px 10px rgba(0, 0, 0, 0.12);
  --shadow-medium: 0 8px 30px rgba(0, 0, 0, 0.12);
  --shadow-large: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-hover: 0 30px 60px rgba(0, 0, 0, 0.12);
  --shadow-sticky: 0 12px 10px -10px rgba(0, 0, 0, 0.12);

  --action-primary: #2194ff;
  --action-primary-darker: #1a387e;
  --action-primary-lighter: #9ab2ea;
  --action-primary-text: white;

  --action-error: #eb3b5a;
  --action-error-text: #ffffff;

  --action-warn: #fa8231;
  --action-warn-text: #ffffff;

  --color-green: #10ac84;
  --color-red: #eb3b5a;
  --color-yellow: #fed330;
  --color-purple: #8854d0;
  --color-orange: #fa8231;
  --color-pink: #f78fb3;
  --color-blue: #546de5;

  --accent-sidebar-background: #fafafa;
  --accent-sidebar-border: #efefef;
}

* {
  box-sizing: border-box;
}

button.unstyled {
  padding: 0px;
  border: none;
  background: none;
}

input,
textarea,
button {
  font-family: 'Inter', sans-serif;
  /* -webkit-appearance: none; */
  -webkit-border-radius: none;
  border-radius: 0;
  outline: none;
}

button {
  user-select: none;
}

::placeholder {
  color: #afafaf;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #afafaf;
}

::-ms-input-placeholder {
  color: #afafaf;
}

a {
  color: #4b7bec;
  text-decoration: none;
}

::-moz-selection {
  background: #3867d6;
  color: white;
}

::selection {
  background: #3867d6;
  color: white;
}

hr {
  height: 1px;
  border: none;
  background: #efefef;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
